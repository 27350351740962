
.overlay {
  opacity: 1;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
}

.App {
  vertical-align: middle;
  position: absolute;
  z-index:-1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("./assets/BG.gif"); 
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

